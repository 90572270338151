import { useState, useEffect, useContext } from 'react';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from './MenuItem';
import SubMenu from './SubMenu';
import MenuContext from 'context/MenuContext';
import { MenuData, IGroup, IMenuData } from './MenuData';
import AuthContext from 'context/AuthContext';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        overflowY: 'scroll',

        '&::-webkit-scrollbar': {
            width: '0px',
            height: '100px',
            background: theme.palette.secondary.light
        },

        '&::-webkit-scrollbar-track': {
            boxShadow: `inset 0 0 6px ${theme.palette.secondary.light}`
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    menuText: {
        padding: '10px',
        marginLeft: '10px',
        marginTop: '10px',
        fontSize: '0.85rem'
    }
}));

const Menu = () => {
    const { session } = useContext(AuthContext);
    const { setPaths } = useContext(MenuContext)
    const [menuData, setMenuData] = useState<Array<IGroup>>([]);

    useEffect(() => {
        if (typeof(session.permissions) !== "undefined") {
            const newData = [];

            for(let md of MenuData) {
                let newGroup:IGroup = {
                    group: md.group,
                    menu: []
                }

                //iterate menu
                for(let m of md.menu) {
                    if(m.name === "Dashboard") {
                        newGroup.menu.push(m);
                        continue;
                    }

                    if(session.permissions.includes(m.id)) {
                        newGroup.menu.push(m);
                        if(m.children.length > 0) {
                            for(let cm of m.children) {
                                if(!session.permissions.includes(cm.id)) {
                                    m.children = m.children.filter(x => x.id !== cm.id);
                                }
                            }
                        }
                    }
                }

                if(newGroup.menu.length > 0) {
                    newData.push(newGroup);
                }
            }

            setMenuData(newData);

            const paths: Array<string> = [];
            iterateGroup(newData, paths);
            setPaths(paths);
        }
    }, [session]);

    return menuData.length > 0 ? <MenuList groups={menuData} /> : null;
}

const MenuList = ({ groups }: { groups: Array<IGroup> }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<string>("");

    useEffect(() => {
        const path = window.location.pathname;

        for(const g of groups) {
            setActiveMenu(g.menu, path);
        }

    }, []);

    const setActiveMenu = (menus: IMenuData[], path: string) => {
        for(const menu of menus) {
            if(menu.path === path) {
                setSelected(menu.id);
                return;
            }
    
            if(menu.children) {
                setActiveMenu(menu.children, path)
            }
        }
        
    }

    const selectMenu = (selectedIndex: string) => {
        setSelected(selectedIndex);
    }

    return (
        <div className={classes.root}>
            <List component="nav" disablePadding>
                {groups.map((group: IGroup, i:number) => {
                    return (
                        <div key={i}>
                            <h5 className={classes.menuText}>{group.group}</h5>
                            {group.menu.map((item: IMenuData) => {
                                return item.children.length ?
                                    <SubMenu item={item} selected={selected} selectMenu={selectMenu} key={item.id} /> :
                                    <MenuItem item={item} selected={selected} selectMenu={selectMenu} key={item.id} />
                            })}
                        </div>
                    )
                })}
            </List>
        </div>
    );
}

const iterateGroup = (groups: Array<IGroup>, paths: Array<string>) => {
    for (let group of groups) {
        iterator(group.menu, paths);
    }
}

const iterator = (menus: Array<IMenuData>, paths: Array<string>) => {
    for (let menu of menus) {
        if (menu.path !== "#") {
            paths.push(menu.path);
        }

        if (menu.children) {
            iterator(menu.children, paths);
        }
    }
    return paths;
}

export default Menu;